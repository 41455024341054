(function() {
	'use strict';

	var hamburger = document.querySelector('.hamburger'),
		nav = document.querySelector('.navigation'),
		navLinks = document.querySelectorAll('.navigation__link'),
		introLinks = document.querySelectorAll('.introduction__link');

	if(hamburger && nav && navLinks && introLinks) {
		addClickEvents();
	}

	function addClickEvents() {
		hamburger.addEventListener('click', function(event) {
			event.preventDefault();

			if(hamburger.classList.contains('hamburger--open')) {
				document.body.classList.remove('no-scroll');
				hamburger.classList.remove('hamburger--open');
				nav.classList.remove('navigation--open');
			}
			else {
				document.body.classList.add('no-scroll');
				hamburger.classList.add('hamburger--open');
				nav.classList.add('navigation--open');
			}
		});

		Array.prototype.forEach.call(navLinks, function(link) {
			link.addEventListener('click', function(event) {
				event.preventDefault();
				document.body.classList.remove('no-scroll');
				hamburger.classList.toggle('hamburger--open');
				nav.classList.toggle('navigation--open');
				setTimeout(function() {
					scrollToSection(link.getAttribute('href'));
				}, 500);
			});
		});

		Array.prototype.forEach.call(introLinks, function(link) {
			link.addEventListener('click', function(event) {
				event.preventDefault();
				scrollToSection(link.getAttribute('href'));
			});
		});
	}

	function scrollToSection(href) {
		var section = document.querySelector(href);
		$('html, body').animate({
			scrollTop: $(section).offset().top - 20
		}, 1000);
	}

})();